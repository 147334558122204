// renderers
import { useEffect, useState } from "react";
import RecordingRenderer from "./columnRenderer/RecordingsRenderer";
import ScoreRenderer from "./columnRenderer/ScoreRenderer";
import TrustScoreRenderer from "./columnRenderer/TrustScoreRenderer";
import ViewReportRenderer from "./columnRenderer/ViewReportRenderer";
import dateColumnFilter from "./columnRenderer/filters/DateColumnFilter";
// filters
import getNumberColumnFilters from "./columnRenderer/filters/NumberColumnFilter";
import stringColumnFilter from "./columnRenderer/filters/StringColumnFilter";
import {
	getInterviewDate,
	getInterviewStatus,
	getRecordingLink,
	getScore,
	getTrustScore,
	interviewStatusComparator,
	nameColumnComparator,
} from "./columnRenderer/filters/valueGetters";

// sorters
import { dateSorter, numberSorter, pronScoreSorter } from "./columnRenderer/filters/valuesSorter";
import dropDownFilter from "./columnRenderer/filters/DropDownColumnFilter";
import { Link } from "react-router-dom";
import InterviewStatusRendererOrganization from "./columnRenderer/InterviewStatusRendererOrganization";
import { getFormattedDateTime3 } from "../../utilities/utilityFunctions";

const useGetColumnsAndRenderersOrganization = () => {
	let initialColumns = [
		{
			field: "createdAt",
			headerName: "Interview Date",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 150,
			renderCell: (params) => (
				<div className="flex items-center w-full h-full">
					<p>{getFormattedDateTime3(params.row.createdAt)}</p>
				</div>
			),
			filterOperators: dateColumnFilter(getInterviewDate),
			sortComparator: dateSorter,
		},
		{
			field: "Opening Name",
			headerName: "Opening Name",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 260,
			headerAlign: "center",
			renderCell: (params) => (
				<Link
					to={`/admin/evaluations/${params.row?.openingData?._id}`}
					className="text-blue-500 no-underline"
				>
					{params.row?.openingData?.title || "No Title"}
				</Link>
			),
			sortable: true,
			filterOperators: stringColumnFilter(nameColumnComparator),
			valueGetter: (_, row) => `${row?.openingData?.title}`,
		},
		{
			field: "Name",
			headerName: "Name",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 200,
			headerAlign: "center",
			renderCell: (params) => (
				<div>
					<p className="text-sm font-semibold">
						{params.row.firstName} {params.row.lastName}
					</p>
					<p className="text-sm text-gray-500 relative w-min flex align-middle items-center">
						{params.row?.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || "N/A"}
					</p>
					<p className="text-sm text-blue-500">{params.row.email}</p>
				</div>
			),
			sortable: true,
			filterOperators: stringColumnFilter(nameColumnComparator),
			valueGetter: (_, row) => `${row.firstName} ${row.lastName}`,
		},
		{
			field: "score",
			headerName: "Score",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 150,
			headerAlign: "center",
			renderCell: (params) => <ScoreRenderer params={params} />,
			filterOperators: getNumberColumnFilters(getScore),
			sortComparator: numberSorter,
			valueGetter: (_, row) => getScore(row),
		},
		{
			field: "trustScore",
			headerName: "Trust Score",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 150,
			headerAlign: "center",
			renderCell: (params) => <TrustScoreRenderer params={params} />,
			filterOperators: getNumberColumnFilters(getTrustScore),
			sortComparator: numberSorter,
			valueGetter: (_, row) => getTrustScore(row),
		},
		{
			field: "interviewStatus",
			headerName: "Interview Status",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 460,
			renderCell: (params) => (
				<InterviewStatusRendererOrganization params={params} openingData={params.row.openingData} />
			),
			headerAlign: "center",
			sortable: false,
			filterOperators: dropDownFilter(
				(row, value) => interviewStatusComparator(row, value, row.openingData),
				[],
				true,
			),
			valueGetter: (_, row) => getInterviewStatus({ ...row, opening: row.openingData }),
		},
		{
			field: "viewReport",
			headerName: "View Report",
			headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
			width: 110,
			renderCell: (params) => (
				<ViewReportRenderer params={params} openingData={params.row.openingData} />
			),
			headerAlign: "center",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
		},
	];

	const generateColumns = () => {
		let columns = [...initialColumns];
		return columns;
	};

	const appendStaticColumns = (columns) => {
		let staticColumns = [
			{
				field: "concatenationId",
				headerName: "Recording",
				headerClassName: "bg-[#F3F4F6] border-left text-xs font-medium uppercase text-gray-500",
				width: 150,
				renderCell: (params) => <RecordingRenderer params={params} />,
				headerAlign: "center",
				sortable: false,
				filterable: false,
				disableColumnMenu: true,
				valueGetter: (_, row) => getRecordingLink(row),
			},
		];

		return [...columns, ...staticColumns];
	};

	const [cols, setCols] = useState(generateColumns([]));
	const [draggedCol, setDraggedCol] = useState(null);

	useEffect(() => {
		let newColumns = generateColumns([]);
		newColumns = appendStaticColumns(newColumns);
		setCols(newColumns);
	}, []);

	const handleDragStart = (col) => {
		setDraggedCol(col);
	};

	const handleDragOver = (e, col, index) => {
		e.preventDefault();
		if (!draggedCol) return;

		const draggedIndex = cols.findIndex((column) => column.field === draggedCol.field);
		if (draggedIndex === index) return;

		const newColumns = [...cols];
		newColumns.splice(draggedIndex, 1);
		newColumns.splice(index, 0, draggedCol);

		setCols(newColumns);
	};

	return cols.map((col, index) => ({
		...col,
		renderHeader: (params) => (
			<div
				draggable={col.field === "Actions" ? false : true}
				onDragStart={col.field === "Actions" ? () => null : () => handleDragStart(col, index)}
				onDragOver={col.field === "Actions" ? () => null : (e) => handleDragOver(e, col, index)}
				style={{ cursor: col.field === "Actions" ? "" : "grab" }}
				className="font-semibold flex flex-1 h-full w-full align-middle items-center transition-all"
			>
				{params.colDef.headerName}
			</div>
		),
	}));
};

export default useGetColumnsAndRenderersOrganization;
