import React from "react";
import InterviewDatePicker from "./InterviewScheduleForms/InterviewDatePicker";
import JoinTimeOptions from "./InterviewScheduleForms/JoinTimeOptions";
import EmailInviteControls from "./InterviewScheduleForms/EmailInviteControls";

function InterviewScheduleForm({
	selectedDate,
	setSelectedDate,
	joinEarly,
	setJoinEarly,
	joinOption,
	setJoinOption,
	timeWindow,
	setTimeWindow,
	isCCInviteActive,
	setIsCCInviteActive,
	isEmailBodyVisible,
	toggleEmailBodyVisibility,
	selectedTimeZone,
	setSelectedTimeZone,
}) {
	return (
		<div className="flex flex-row flex-wrap gap-6">
			<div className="flex-1">
				<InterviewDatePicker
					selectedDate={selectedDate}
					setSelectedDate={setSelectedDate}
					joinEarly={joinEarly}
					setJoinEarly={setJoinEarly}
					selectedTimeZone={selectedTimeZone}
					setSelectedTimeZone={setSelectedTimeZone}
				/>
			</div>
			<div className="flex-1">
				<JoinTimeOptions
					joinOption={joinOption}
					setJoinOption={setJoinOption}
					timeWindow={timeWindow}
					setTimeWindow={setTimeWindow}
				/>
				<EmailInviteControls
					isCCInviteActive={isCCInviteActive}
					setIsCCInviteActive={setIsCCInviteActive}
					isEmailBodyVisible={isEmailBodyVisible}
					toggleEmailBodyVisibility={toggleEmailBodyVisibility}
				/>
			</div>
		</div>
	);
}

export default InterviewScheduleForm;
