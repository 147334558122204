import React from "react";
import Flatpickr from "react-flatpickr";
import { toast } from "react-toastify";
import "flatpickr/dist/flatpickr.min.css";
import moment from "moment-timezone";
import { Autocomplete, TextField, Box } from "@mui/material";
import { getFormattedDateTime4 } from "../../../utilities/utilityFunctions";

function InterviewDatePicker({
	selectedDate,
	setSelectedDate,
	joinEarly,
	setJoinEarly,
	selectedTimeZone,
	setSelectedTimeZone,
}) {
	let maxDate = new Date(new Date().toLocaleString("en-US", { timeZone: selectedTimeZone })).setDate(
		new Date().getDate() + 60, // maximum allow 2 month
	);
	let minDate = new Date(new Date().toLocaleString("en-US", { timeZone: selectedTimeZone }));

	const timeZones = moment.tz.names().map((tz) => {
		const offset = moment.tz(tz).utcOffset();
		const hours = Math.floor(offset / 60);
		const minutes = Math.abs(offset % 60);
		const sign = offset >= 0 ? "+" : "-";
		const label = `(UTC${sign}${Math.abs(hours).toString().padStart(2, "0")}:${minutes
			.toString()
			.padStart(2, "0")}) ${tz.replace("_", " ")}`;
		return {
			label,
			value: tz,
		};
	});

	const handleChange = (event, newValue) => {
		if (newValue) {
			setSelectedTimeZone(newValue.value);
			const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: newValue.value }));
			if (currentDate > selectedDate) setSelectedDate(currentDate);
		}
	};

	return (
		<>
			<Box>
				<Autocomplete
					className="mt-2"
					options={timeZones}
					getOptionLabel={(option) => option.label}
					value={timeZones.find((tz) => tz.value === selectedTimeZone) || null}
					onChange={handleChange}
					renderInput={(params) => (
						<TextField {...params} label="Choose Time Zone" variant="outlined" />
					)}
				/>
			</Box>
			<div
				style={{
					boxShadow: "0 -4px 6px -1px rgba(0, 0, 0, 0.1)",
					border: ".5px solid gray",
					borderRadius: "8px",
					padding: "3px",
					marginTop: "5px",
				}}
			>
				<Flatpickr
					value={selectedDate}
					options={{
						enableTime: true,
						dateFormat: "D M d Y h:i K",
						minDate: minDate,
						maxDate: maxDate,
						minTime: "00:00",
						maxTime: "23:59",
						inline: true,
						onChange: (dates) => {
							try {
								const newSelectedDate = new Date(dates[0].getTime());
								if (newSelectedDate > maxDate) {
									setSelectedDate(maxDate);
									toast.warn(
										`The maximum allowed time is ${getFormattedDateTime4(maxDate)}.`,
									);
								} else if (newSelectedDate < minDate) {
									setSelectedDate(minDate.setMinutes(minDate.getMinutes() + 1));
								} else {
									setSelectedDate(newSelectedDate);
								}
							} catch (e) {
								console.log(e);
							}
						},
					}}
					style={{ display: "none" }}
				/>
			</div>
			<div className="mt-4">
				<label className="block mb-2">
					<input
						type="checkbox"
						id="joinEarly"
						className="mr-2"
						checked={joinEarly}
						onChange={() => setJoinEarly(!joinEarly)}
					/>
					Allow candidate to join 10 minutes before scheduled time
				</label>
			</div>
		</>
	);
}

export default InterviewDatePicker;
